body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-header-flex {
  display: flex;
  justify-content: space-between;
}

.card-connect-button {
  display: flex;
  justify-content: center;
}

.modal-submit-button {
  display: flex;
  justify-content: center;
}

.logo-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.logo {
  width: 35%;
}

.title-subtile {
  font-size: x-large;
}

.server-list-page-container {
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.server-list {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.server-list-header {
  font-size: 24px;
  text-align: center;
  margin: 15px;
}

.add-server-button {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.add-server-modal {
  display: flex;
  justify-content: space-between;
}

.dropdown-filter {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding-top: 1.5rem;
  justify-content: center;
}

.dropdown {
  width: 10rem;
}

.dropdown-row {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 800px) {
  .mona-lisa-banner {
    justify-content: center;
  }

  .logo {
    display: none;
  }

  .title-subtile {
    text-align: center;
  }

  .mobile-no-display {
    display: none;
  }

  .dropdown-row {
    flex-direction: column;
    text-align: center;
  }

  .dropdown-filter {
    align-self: center;
    margin: 0.1em;
    padding-top: 0.5rem;
  }

  .dropdown-filter-last {
    padding-bottom: 1rem;
  }

  .server-list-page-container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.mona-lisa-banner-logo {
  max-height: 50px;
  height: auto;
  width: auto;
}

.player-checkbox-container {
  background-color: #ffffff;
  height: auto;
  max-width: 100%;
  margin: 15px;
  width: 800px;
}

.player-select-header {
  padding: 15px;
  font-size: xx-large;
}

.player-select-button {
  margin-bottom: 15px;
}

.player-select {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
